<template>
  <v-container style="max-width: 800px; position: relative;">
      <Header />
      <ReportForm />
      <Footer />
  </v-container>
</template>

<script>
import Header from '../components/Header.vue'
import ReportForm from '../components/ReportForm.vue'
import Footer from '../components/Footer.vue'

export default {
    components: {
        Header,
        ReportForm,
        Footer
    }
}
</script>

